import { playSound, topicDisplayName, topicShortUrl, urlB64ToUint8Array } from "./utils";
import { toNotificationParams } from "./notificationUtils";
import prefs from "./Prefs";
import routes from "../components/routes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import chromeGrantNow2 from "../img/gifs/Chrome/ChromeGrantNow2.gif";

import chromeReEnabled1 from "../img/gifs/Chrome/ChromeReEnabled1.gif";
import chromeReEnabled2 from "../img/gifs/Chrome/ChromeReEnabled2.gif";
import chromeReEnabled3 from "../img/gifs/Chrome/ChromeReEnabled3.gif";


import edgeReEnabled1 from "../img/gifs/Edge/EdgeReEnabled1.gif";
import edgeReEnabled2 from "../img/gifs/Edge/EdgeReEnabled2.gif";
import edgeReEnabled3 from "../img/gifs/Edge/EdgeReEnabled3.gif";





/**
 * The notifier is responsible for displaying desktop notifications. Note that not all modern browsers
 * support this; most importantly, all iOS browsers do not support window.Notification.
 */
class Notifier {
  async notify(subscription, notification) {
    if (!this.supported()) {
      return;
    }

    await this.playSound();

    const shortUrl = topicShortUrl(subscription.baseUrl, subscription.topic);
    const defaultTitle = topicDisplayName(subscription);

    console.log(`[Notifier, ${shortUrl}] Displaying notification ${notification.id}`);

    const registration = await this.serviceWorkerRegistration();
    await registration.showNotification(
      ...toNotificationParams({
        subscriptionId: subscription.id,
        message: notification,
        defaultTitle,
        topicRoute: new URL(routes.forSubscription(subscription), window.location.origin).toString(),
      })
    );
  }

  async playSound() {
    // Play sound
    const sound = await prefs.sound();
    if (sound && sound !== "none") {
      try {
        await playSound(sound);
      } catch (e) {
        console.log(`[Notifier] Error playing audio`, e);
      }
    }
  }

  async webPushSubscription(hasWebPushTopics) {
    const pushManager = await this.pushManager();
    const existingSubscription = await pushManager.getSubscription();
    if (existingSubscription) {
      return existingSubscription;
    }

    // Create a new subscription only if there are new topics to subscribe to. It is possible that Web Push
    // was previously enabled and then disabled again in which case there would be an existingSubscription.
    // If, however, it was _not_ enabled previously, we create a new subscription if it is now enabled.

    if (hasWebPushTopics) {
      return pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlB64ToUint8Array(config.web_push_public_key),
      });
    }

    return undefined;
  }

  async pushManager() {
    return (await this.serviceWorkerRegistration()).pushManager;
  }

  async serviceWorkerRegistration() {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      throw new Error("No service worker registration found");
    }
    return registration;
  }

  notRequested() {
    return this.supported() && Notification.permission === "default";
  }

  granted() {
    return this.supported() && Notification.permission === "granted";
  }

  denied() {
    return this.supported() && Notification.permission === "denied";
  }

  async maybeRequestPermission() {
    if (!this.supported()) {
      return false;
    }

    return new Promise((resolve) => {
      Notification.requestPermission((permission) => {
        resolve(permission === "granted");
      });
    });
  }



  // async comRequestPermission() {
  //   if (!this.supported()) {
  //     return false;
  //   }

  //   // Construct the URL for Chrome site settings
  //   const currentUrl = encodeURIComponent(window.location.origin);
  //   const settingsUrl = `chrome://settings/content/siteDetails?site=${currentUrl}`;

  //   // Open the Chrome settings page in a new tab
  //   const gifUrl = chromeReEnabled1;
  //   Swal.fire({
  //     allowOutsideClick: false,
  //     title: "<strong>Allow Notifications</strong>",
  //     html: `<p><strong>Step 1:</strong> Copy Url and Paste to the new Tab<em>Change</em> to allow notifications.</p>`, 
  //     imageUrl: gifUrl,
  //     imageAlt: "Custom GIF",
  //     showCloseButton: true,
  //     showCancelButton: false,
  //     focusConfirm: false,
  //     confirmButtonText: `
  //       <i class="fa fa-thumbs-up" id="copyButton">Copy Url!</i>
  //     `,
  //     confirmButtonAriaLabel: "Thumbs up, great!",
  //     didOpen: () => {
  //       const copyButton = document.getElementById('copyButton');
  //       copyButton.addEventListener('click', () => {
  //         copyToClipboard();
  //       });
  //     }
  //   });

  //   function copyToClipboard() {
  //     const url = settingsUrl;
  //     navigator.clipboard.writeText(url).then(() => {
  //     }).catch(err => {
  //       console.error('Failed to copy: ', err);
  //     });
  //   }






  //   // Return true as the action was executed
  //   return true;
  // }


  async comRequestPermission() {
    if (!this.supported()) {
      return false;
    }

    // Construct the URL for Chrome site settings
    const currentUrl = encodeURIComponent(window.location.origin);
    const settingsUrl = `chrome://settings/content/siteDetails?site=${currentUrl}`;

    // Open the Chrome settings page in a new tab
    let gifUrl = chromeReEnabled1;
    Swal.fire({
      allowOutsideClick: false,
      title: "<strong>Allow Notifications</strong>",
      html: `<strong>Follow these steps:</strong>
      <p>1. Copy the URL.</p>
      <p>2. Open a new tab.</p>
      <p>3. Paste the URL into the new tab.</p>
      <p>4. <em>Change</em> the notification permissions to "Allow."</p>
      `, 
      imageUrl: gifUrl,
      imageAlt: "Custom GIF",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: `
        <i class="fa fa-thumbs-up" id="copyButton">Copy Url!</i>
      `,
      confirmButtonAriaLabel: "Thumbs up, great!",
      didOpen: () => {
        const copyButton = document.getElementById('copyButton');
        copyButton.addEventListener('click', () => {
          copyToClipboard();
        });
      }
    });

    function copyToClipboard() {
      const url = settingsUrl;
      navigator.clipboard.writeText(url).then(() => {
        // Open the URL in a new tab
        window.open(url, '_blank');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }

    // Start checking if the permission changes to "granted"
    let permissionCheckInterval = setInterval(async () => {
      if (Notification.permission === 'granted') {
        clearInterval(permissionCheckInterval); // Stop checking once permission is granted

        // Show the second notification with the third GIF
        gifUrl = chromeReEnabled3;
        Swal.fire({
          allowOutsideClick: false,
          title: "<strong>Go To Settings</strong>",
          html: `<p><strong>Step 2:</strong> Enable Background Notifications</p>`,
          imageUrl: chromeGrantNow2,
          imageAlt: "Custom GIF",
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Great!',
          confirmButtonAriaLabel: "Thumbs up, notifications enabled!"
        });
      }
    }, 1000); // Check every second if the permission has been granted

    // Return true as the action was executed
    return true;
  }




  supported() {
    return this.browserSupported() && this.contextSupported();
  }

  browserSupported() {
    return "Notification" in window;
  }

  pushSupported() {
    return config.enable_web_push && "serviceWorker" in navigator && "PushManager" in window;
  }

  pushPossible() {
    return this.pushSupported() && this.contextSupported() && this.granted() && !this.iosSupportedButInstallRequired();
  }

  /**
   * Returns true if this is a HTTPS site, or served over localhost. Otherwise the Notification API
   * is not supported, see https://developer.mozilla.org/en-US/docs/Web/API/notification
   */
  contextSupported() {
    return window.location.protocol === "https:" || window.location.hostname.match("^127.") || window.location.hostname === "localhost";
  }

  // no PushManager when not installed, but it _is_ supported.
  iosSupportedButInstallRequired() {
    return (
      config.enable_web_push &&
      // a service worker exists
      "serviceWorker" in navigator &&
      // but the pushmanager API is missing, which implies we're on an iOS device without installing
      !("PushManager" in window) &&
      // check that this is the case by checking for `standalone`, which only exists on Safari
      window.navigator.standalone === false
    );
  }
}

const notifier = new Notifier();
export default notifier;
