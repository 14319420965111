import React, { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ChromeGrantNow1 from "../img/gifs/Chrome/ChromeGrantNow1.gif";
import ChromeGrantNow2 from "../img/gifs/Chrome/ChromeGrantNow2.gif";
import EdgeGrantNow1 from "../img/gifs/Edge/EdgeGrantNow1.gif"; 
import EdgeGrantNow2 from "../img/gifs/Edge/EdgeGrantNow2.gif"; 

const MySwal = withReactContent(Swal);

const MyAlertComponent = () => {
  useEffect(() => {
    const checkForElementsAndShowAlert = () => {
      const elements = Array.from(document.querySelectorAll("*")).filter((element) => element.textContent.includes("Grant now"));

      if (elements.length > 0) {
        const getBrowser = () => {
          return window.navigator.userAgent.indexOf("Edg") > -1 ? "Edge" : "Chrome";
        };

        const showFirstAlert = async () => {
          const browser = getBrowser();
          const gifUrlFirst = browser === "Chrome" ? ChromeGrantNow1 : EdgeGrantNow1;

          // Show the first alert
          await MySwal.fire({
            allowEscapeKey: false,
            title: "Allow Notifications in 2 Steps",
            html: `<p><strong>Step 1:</strong> Click <em>"Grant Now"</em> to allow notifications.</p>`, 
            imageUrl: gifUrlFirst,
            imageAlt: "Custom GIF",
            allowOutsideClick: false,
            confirmButtonText: "OK"
          });

          // Start checking if the notification is granted
          checkPermissionInLoop();
        };

        const checkPermissionInLoop = () => {
          const interval = setInterval(() => {
            // Check if permission is granted
            if (Notification.permission === "granted") {
              clearInterval(interval); // Stop the loop when granted
              showSecondAlert();
            }
          }, 1000); // Check every second
        };

        const showSecondAlert = async () => {
          const browser = getBrowser();
          const gifUrlSecond = browser === "Chrome" ? ChromeGrantNow2 : EdgeGrantNow2;

          // Show the second alert
          await MySwal.fire({
            allowEscapeKey: false,
            title: "Go to Settings",
            html: `<p><strong>Step 2:</strong> Click <em>"Background Notification"</em> to allow notifications.</p>`,
            imageUrl: gifUrlSecond,
            imageAlt: "Custom GIF",
            allowOutsideClick: false,
            confirmButtonText: "Great!"
          });
        };

        showFirstAlert();
      }
    };

    checkForElementsAndShowAlert();
  }, []);

  return null; // This component doesn't need to render anything
};

export default MyAlertComponent;
